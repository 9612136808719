import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import cx from 'classnames'

import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'

import { darkTheme, lightTheme } from '../themes'
import { ResponsiveNavList } from '../components/nav'
import logoImage from '../images/tyto-logo-64.png'

//decide which dynamic next step buttons to show based on whether they've logged inspect
//or signed up before. highlight the most likely button by making it contained and secondary

//const isLoggedIn = false //TODO
//const isSignedUp = false //TODO

const useStyles = makeStyles(
	theme => ({
		root: {
			padding: theme.spacing(1, 0),
			transition: theme.transitions.create('padding', {
				duration: theme.transitions.duration.shorter,
			}),

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(2, 0),
			},
		},
		container: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'space-between',
			transition: theme.transitions.create('padding', {
				duration: theme.transitions.duration.shorter,
			}),
		},
		landingPage: {
			backgroundColor: theme.palette.background.landingPage,
		},
		logo: {
			alignItems: 'center',
			display: 'flex',
			textDecoration: 'none',
		},
		logoImage: {
			marginRight: theme.spacing(2),
			transition: theme.transitions.create('width', {
				duration: theme.transitions.duration.shorter,
			}),
			width: theme.spacing(6),

			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(7),
			},

			[theme.breakpoints.up('md')]: {
				width: theme.spacing(8),
			},
		},
		logoText: {
			color: theme.palette.text.primary,
			fontSize: theme.spacing(3),
			fontWeight: 500,
			height: theme.spacing(4),
			lineHeight: `${theme.spacing(4)}px`,
			marginRight: theme.spacing(2),
			transition: theme.transitions.create('height', {
				duration: theme.transitions.duration.shorter,
			}),

			[theme.breakpoints.up('sm')]: {
				fontSize: theme.spacing(3.5),
				height: theme.spacing(4.5),
				lineHeight: `${theme.spacing(4.5)}px`,
			},

			[theme.breakpoints.up('md')]: {
				fontSize: theme.spacing(4),
				height: theme.spacing(5),
				lineHeight: `${theme.spacing(5)}px`,
			},
		},
		nav: {
			alignItems: 'center',
			display: 'inline-flex',
			flexWrap: 'wrap',
			justifyContent: 'flex-end',
		},
		signupButton: {
			marginLeft: theme.spacing(2),
		},
	}),
	{ name: 'Header' }
)

const Header = ({ isLandingPage }) => {
	const classes = useStyles()
	return (
		<ThemeProvider theme={isLandingPage ? darkTheme : lightTheme}>
			<header
				className={cx(classes.root, {
					[classes.landingPage]: isLandingPage,
				})}
			>
				<Container className={classes.container}>
					<GatsbyLink className={classes.logo} to={'/'}>
						<img
							className={classes.logoImage}
							alt={'Logo'}
							src={logoImage}
						/>
						<Typography className={classes.logoText}>
							tyto
						</Typography>
					</GatsbyLink>
					<ResponsiveNavList />
				</Container>
			</header>
		</ThemeProvider>
	)
}

Header.propTypes = {
	isLandingPage: PropTypes.bool,
}

export default Header
