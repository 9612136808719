export const link = theme => ( {
	color: theme.palette.primary.main,
	textDecoration: 'none',

	'&:hover': {
		color: theme.palette.primary.light,
		textDecoration: 'underline',
	},
} )

export const container = theme =>
	theme.mixins.gutters( {
		margin: '0 auto',
		width: '100%',

		[theme.breakpoints.up( 'xl' )]: {
			width: theme.spacing(8 * 20),
		},

		[theme.breakpoints.only( 'lg' )]: {
			width: theme.spacing(8 * 18),
		},

		[theme.breakpoints.only( 'md' )]: {
			width: theme.spacing(8 * 14),
		},

		[theme.breakpoints.only( 'sm' )]: {
			width: theme.spacing(8 * 9),
		},
	} )
