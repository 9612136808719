const { palette } = require('./theme')

module.exports = {
	siteTitle: 'Tyto | Remote Work Done Fun', // Navigation and Site Title
	siteTitleShort: 'tyto', // Short_name for manifest
	siteDescription:
		'Tyto is the all-in-one place to hang out with your team, combining chat, tasks, projects and video.',
	siteKeywords: [],
	lang: 'en', // Language Tag on <html> element
	pathPrefix: '/',

	// Manifest and Progress color
	themeColor: palette.primary.main,
	backgroundColor: '#f8f8f8',
}
