import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/fp/map'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import Link from '../components/Link'

const useStyles = makeStyles(
	theme => ({
		root: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: theme.spacing(2),
		},
		link: {
			color: theme.palette.text.primary,
			fontWeight: '700',
			marginBottom: theme.spacing(2),
		},
	}),
	{ name: 'LinkList' }
)

const LinkList = ({ links, title }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			{title && (
				<Typography variant={'overline'} gutterBottom>
					{title}
				</Typography>
			)}
			{map(({ title, url }) => (
				<Link
					className={classes.link}
					color={'inherit'}
					key={`${title}-${url}`}
					to={url}
					variant={'body2'}
				>
					{title}
				</Link>
			))(links)}
		</div>
	)
}

LinkList.propTypes = {
	links: PropTypes.array,
	title: PropTypes.string,
}

export default LinkList
