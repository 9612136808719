import React, { useState } from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { animated, useTransition } from 'react-spring'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core'
import { Close as CloseIcon, Menu as MenuIcon } from 'mdi-material-ui'

const useStyles = makeStyles(
	theme => ({
		root: {
			flexGrow: 1,
			position: 'relative',
		},
		closeButton: {
			alignSelf: 'flex-end',
			marginRight: theme.spacing(2),
		},
		list: {
			alignItems: 'flex-start',
			backgroundColor: theme.palette.secondary.main,
			color: 'white',
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			padding: theme.spacing(1, 0),
			width: '80vw',
		},
		listItem: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
		listItemText: {
			fontSize: theme.typography.h5.fontSize,
		},
		menuButton: {
			'& svg': {
				transition: theme.transitions.create('transform', {
					duration: theme.transitions.duration.shorter,
				}),
				transformOrigin: 'center',
			},

			'&:hover svg': {
				transform: 'scaleY(1.15)',
			},
		},
		nav: {
			alignItems: 'center',
			display: 'inline-flex',
			flexWrap: 'nowrap',
			justifyContent: 'flex-end',
		},
		position: {
			position: 'absolute',
			right: 0,
			top: '50%',
			transform: 'translateY(-50%)',
		},
		signupButton: {
			marginLeft: theme.spacing(2),
			whiteSpace: 'nowrap',
		},
	}),
	{ name: 'ResponsiveNavList' }
)

const navLinks = [
	{ title: 'Features', url: '/features' },
	{ title: 'Pricing', url: '/pricing' },
	{ title: 'Login', url: '/login' },
	{
		title: 'Try it for free!',
		url: '/signup',
		isPrimary: true,
	},
]

const ResponsiveNavList = () => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)
	const isExpanded = useMediaQuery(theme => theme.breakpoints.up('sm'))
	//const navigate = useNavigate()
	const transitions = useTransition(isExpanded, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})
	const classes = useStyles()

	const navigateTo = url => event => {
		event.stopPropagation()
		navigate(url)
	}

	const toggleDrawer = open => () => {
		setDrawerIsOpen(open)
	}

	const primaryButtonProps = {
		className: classes.signupButton,
		color: 'primary',
		variant: 'contained',
	}

	return (
		<div className={classes.root}>
			{transitions((props, item, key) =>
				item ? (
					<animated.div style={props}>
						<nav className={cx(classes.position, classes.nav)}>
							{navLinks.map(({ isPrimary, title, url }) => (
								<Button
									component={GatsbyLink}
									color={'inherit'}
									key={url}
									to={url}
									{...(isPrimary ? primaryButtonProps : {})}
								>
									{title}
								</Button>
							))}
						</nav>
					</animated.div>
				) : (
					<animated.div style={props}>
						<IconButton
							aria-label={'menu'}
							className={cx(classes.position, classes.menuButton)}
							color={'inherit'}
							onClick={toggleDrawer(true)}
						>
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor={'right'}
							open={drawerIsOpen}
							onClose={toggleDrawer(false)}
						>
							<List
								className={classes.list}
								role={'presentation'}
								onClick={toggleDrawer(false)}
							>
								<IconButton
									className={classes.closeButton}
									color={'inherit'}
									onClick={toggleDrawer(false)}
								>
									<CloseIcon />
								</IconButton>
								{navLinks.map(({ isPrimary, title, url }) => (
									<ListItem
										button
										className={classes.listItem}
										onClick={navigateTo(url)}
										key={url}
									>
										<ListItemText
											primary={title}
											primaryTypographyProps={{
												className: classes.listItemText,
											}}
										/>
									</ListItem>
								))}
							</List>
						</Drawer>
					</animated.div>
				)
			)}
		</div>
	)
}

export default ResponsiveNavList
