import React from 'react'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'

const useStyles = makeStyles(
	theme => {
		const sidebarWidth = theme.spacing(24)
		return {
			nav: {
				bottom: 0,
				overflowY: 'auto',
				padding: theme.spacing(2, 2, 2, 0),
				position: 'sticky',
				right: 0,
				top: 0,
				width: sidebarWidth,
			},
			wrapper: {
				bottom: 0,
				position: 'absolute',
				right: -theme.spacing(3),
				top: 0,
				transform: 'translateX(100%)',
			},
		}
	},
	{ name: 'FloatingVerticalNav' }
)

const FloatingVerticalNav = ({ className, ...rest }) => {
	const classes = useStyles()
	return (
		<Hidden smDown>
			<div className={classes.wrapper}>
				<nav className={cx(classes.nav, className)} {...rest} />
			</div>
		</Hidden>
	)
}

export default FloatingVerticalNav
