import React from 'react'
import cx from 'classnames'

import { darken, fade, lighten, makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemText } from '@material-ui/core'

import Link from '../Link'

const useStyles = makeStyles(
	theme => {
		const accent =
			theme.type === 'dark'
				? lighten(theme.palette.divider, 0.3)
				: darken(theme.palette.divider, 0.3)
		return {
			listItem: {
				backgroundColor: 'transparent',
				borderLeft: `2px solid transparent`,

				'&:hover, &$listItemSelected:hover': {
					backgroundColor: 'transparent',
				},

				'&$listItemSelected': {
					backgroundColor: 'transparent',
				},
			},
			listItemSelected: {},
			listItemText: {
				fontSize: '0.8125rem',
			},
			'variant-default': {
				borderLeft: `2px solid transparent`,
				'&:hover, &$listItemSelected:hover': {
					borderLeft: `2px solid ${fade(
						accent,
						theme.palette.action.hoverOpacity
					)}`,
				},

				'&$listItemSelected': {
					borderLeft: `2px solid ${accent}`,
				},
			},
			'variant-page': {
				borderRadius: theme.shape.borderRadius,

				'&:hover, &$listItemSelected:hover': {
					backgroundColor: theme.palette.action.hover,
				},
				'&$listItemSelected': {
					backgroundColor: theme.palette.action.selected,

					'& .MuiTypography-body2': {
						fontWeight: theme.typography.fontWeightMedium,
					},
				},
			},
		}
	},
	{ name: 'VerticalNavItem' }
)

const VerticalNavItem = ({ isSelected, title, url, variant = 'default' }) => {
	const classes = useStyles()
	return (
		<ListItem
			button
			classes={{
				root: cx(classes.listItem, classes[`variant-${variant}`]),
				selected: classes.listItemSelected,
			}}
			component={Link}
			dense
			selected={isSelected}
			to={url}
		>
			<ListItemText
				primary={title}
				primaryTypographyProps={{
					classes: { root: classes.listItemText },
				}}
			/>
		</ListItem>
	)
}

export default VerticalNavItem
