import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'

import imgPlayStore from '../images/downloadimage-googleplay.svg'

const styles = theme => ({
	downloadButton: {
		transition: theme.transitions.create(),
		'&:hover': {
			boxShadow: theme.shadows[24],
			transform: 'scale( 1.02 )',
		},
	},
})

const DownloadButtonAndroid = ({ classes }) => {
	return (
		<ButtonBase
			variant="contained"
			color="secondary"
			component="a"
			href="https://play.google.com/store/apps/details?id=me.tyto&hl=en"
		>
			<img
				src={imgPlayStore}
				alt="Download from Google Play Store"
				className={classes.downloadButton}
			/>
		</ButtonBase>
	)
}

export default withStyles(styles)(DownloadButtonAndroid)
