import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core/styles'
import {
	Box,
	Container,
	Grid,
	Link as MuiLink,
	Typography,
} from '@material-ui/core'

import { link } from '../sharedStyles'
import DownloadButtonAndroid from './DownloadButtonAndroid'
import DownloadButtonIos from './DownloadButtonIos'
import Link from './Link'
import LinkList from './LinkList'

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.footer,
		padding: theme.spacing(8),
	},
	menu: {
		listStyleType: 'none',
		padding: 0,

		'& a': {
			color: theme.palette.text.secondary,
			textDecoration: 'none',

			'&:hover': {
				color: theme.palette.text.primary,
			},
		},
	},
	link: link(theme),
	copyright: {
		display: 'block',
		marginTop: theme.spacing(4),
	},
	paddingBottomHuge: {
		paddingBottom: theme.spacing(10),
	},
})

class Footer extends PureComponent {
	render() {
		const { classes } = this.props

		const tytoLinks = {
			title: 'Tyto',
			links: [
				{ title: 'Features', url: '/features' },
				{ title: 'Pricing', url: '/pricing' },
				{ title: 'Signup', url: '/signup' },
				{ title: 'Help', url: '/help' },
			],
		}
		const addOnsLinks = {
			title: 'Add-ons',
			links: [
				{ title: 'Tyto for Mobile', url: '/mobile' },
				{ title: 'Tyto for Gmail', url: '/gmail' },
				{ title: 'Tyto for Enterprise', url: '/enterprise' },
			],
		}

		return (
			<footer className={classes.root}>
				<Container>
					<Typography
						color={'inherit'}
						align={'center'}
						gutterBottom
						variant={'h4'}
					>
						Get Tyto's Mobile App
					</Typography>
					<Typography
						color={'inherit'}
						align={'center'}
						gutterBottom
						variant={'h6'}
					>
						Manage your office from your pocket.
					</Typography>

					<Grid
						container
						spacing={2}
						justify={'center'}
						className={classes.paddingBottomHuge}
					>
						<Grid item>
							<DownloadButtonIos />
						</Grid>
						<Grid item>
							<DownloadButtonAndroid />
						</Grid>
					</Grid>

					<Grid container justify={'center'} spacing={1}>
						<Grid item xs={12} sm={4}>
							<Box mb={3}>
								<Typography variant={'h5'}>Tyto</Typography>
								<Typography>Work done fun</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<LinkList {...tytoLinks} />
						</Grid>

						<Grid item xs={12} sm={4}>
							<LinkList {...addOnsLinks} />
						</Grid>

						{/*<Grid item xs={6} sm={3}>
								<Typography variant="overline" gutterBottom>
									Follow Us
								</Typography>
								<Typography
									component="a"
									href="/mobile"
									className={classes.linkBold}
								>
									Facebook
								</Typography>
								<Typography
									component="a"
									href="/gmail"
									className={classes.linkBold}
								>
									Twitter
								</Typography>
							</Grid>*/}
					</Grid>

					<Typography
						className={classes.copyright}
						color={'textSecondary'}
						variant={'caption'}
					>
						© Copyright 2016 Tyto - All Rights Reserved &middot; An{' '}
						<MuiLink
							className={classes.link}
							href={'https://www.irj.io'}
							rel={'noopener noreferrer'}
							target={'_blank'}
						>
							IRJ.io
						</MuiLink>{' '}
						site. &middot;{' '}
						<Link to={'/legal/privacy'} className={classes.link}>
							Privacy Policy
						</Link>
					</Typography>
				</Container>
			</footer>
		)
	}
}

export default withStyles(styles)(Footer)
