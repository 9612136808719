import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'

import imgAppStore from '../images/downloadimage-appstore.svg'

const styles = theme => ({
	downloadButton: {
		transition: theme.transitions.create(),
		'&:hover': {
			boxShadow: theme.shadows[24],
			transform: 'scale( 1.02 )',
		},
	},
})

const DownloadButtonIos = ({ classes }) => {
	return (
		<ButtonBase
			variant="contained"
			color="secondary"
			component="a"
			href="https://apps.apple.com/gb/app/tyto-team-task-manager/id1077056429"
			rel="noopener noreferrer"
			target="_blank"
		>
			<img
				src={imgAppStore}
				alt="Download from the App Store"
				className={classes.downloadButton}
			/>
		</ButtonBase>
	)
}

export default withStyles(styles)(DownloadButtonIos)
