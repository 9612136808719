import React from 'react'
import Helmet from 'react-helmet'
import { animated, useSpring } from 'react-spring'

import { makeStyles } from '@material-ui/core/styles'

import config from '../../config/website'
import Footer from './Footer'
import Header from './Header'

const useStyles = makeStyles(
	theme => ({
		content: {
			minHeight: '50vh',
		},
	}),
	{ name: 'Layout' }
)

const Layout = ({ children, frontmatter = {}, isLandingPage }) => {
	const springStyle = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
	})
	const classes = useStyles()

	const {
		keywords = config.siteKeywords,
		description = config.siteDescription,
		title = config.siteTitle,
	} = frontmatter

	return (
		<>
			<Helmet
				title={title}
				meta={[
					{ name: 'description', content: description },
					{ name: 'keywords', content: keywords.join() },
				]}
			>
				<html lang={config.lang} />
				<noscript>
					This site runs best with JavaScript enabled.
				</noscript>
			</Helmet>
			<Header isLandingPage={isLandingPage} />
			<animated.div style={springStyle}>
				<main className={classes.content}>{children}</main>
			</animated.div>
			<Footer />
		</>
	)
}

export default Layout
